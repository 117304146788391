import React from 'react';
import UserProvider from '../../Providers/UserProvider'
import MuiThemeProvider from 'material-ui/styles/MuiThemeProvider'
import getMuiTheme from 'material-ui/styles/getMuiTheme'
import RaisedButton from 'material-ui/RaisedButton';
import TextField from 'material-ui/TextField';

class Login extends React.Component {
  state = {
    username: "", 
    password: ""
  };

  handleUsernameChange = (e) => {
    const value = e.target.value;

    this.setState({
      username: value,
    });
  };

  handlePasswordChange = (e) => {
    const value = e.target.value;

    this.setState({
      password: value,
    });
  };

  onPressLogin = (e) => {
    console.log('process.env.NODE_ENV', process.env.NODE_ENV)
    console.log(process.environment)
    UserProvider.login(this.state.username, this.state.password, (data, error)=>{
      if (error) {
        alert (JSON.stringify(error))
      }
    })
  };


  render() {
    return (
      <MuiThemeProvider>
        <div id='login-form'>
          <br/>
          <TextField
               hintText="username"
               floatingLabelText="Enter Username"
               floatingLabelFixed={false}
               value={this.state.username}
               onChange={this.handleUsernameChange}
             />
          <br />
          <TextField
            hintText="password"
            floatingLabelText="Enter Password"
            type="password"
            value={this.state.password}
            onChange={this.handlePasswordChange}
          />
          <br />
          <RaisedButton label="Login" primary={true} onClick={this.onPressLogin} />
        </div>
      </MuiThemeProvider>
    );
  }
}

export default Login;
