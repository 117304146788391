import BatchesProvider from "./BatchesProvider";
import { Promise } from "bluebird";
import QikikAdminProvider from '../UI/qikikAdminUI/providers/QikikAdminProvider';
import AAPI from "./../thirdparty/AAPI";

import _ from 'lodash';
class LinkProvider {
    addNodeLink(tangentTag, batchId, links, createdBy) {

        let items = _.map(links, link => {
            return {
                "type": "node_links",
                "createdBy": createdBy,
                "metadata": {
                    "tenantTag": tangentTag,
                    "nodeId": link.nodeId,
                    "label": link.label,
                    "linkedNodes":
                        link.linkedNodes
                }
            }
        })
        return BatchesProvider.addBatchItem(batchId, items);
    }

    createNodeLinks(tenantTag, batchId, toc,  assignments,  links, username) {

        

        let externalLinks = _.filter(links, { relationship: 'external' });
        let internalLinks = _.filter(links, { relationship: "internal" });
        if(externalLinks.length === 0 && internalLinks.length ===0){
            console.log("Links are empty");
                return Promise.resolve([]);
        }
         return Promise.all([this.processExternalLinks( tenantTag, externalLinks, toc, assignments ),
         this.processInternalLinks(tenantTag,  internalLinks, toc, assignments)])
            .then(data => {
                let linkData =_.flattenDeep(data)
                let nodeIds = _.uniq(_.map(linkData,"nodeId"));
                
                 linkData = _.map(nodeIds,id =>{
                     let data = _.filter(linkData,{nodeId:id});
                     let linkItem = _.find(linkData,{nodeId:id});
                     linkItem.linkedNodes = _.map(data,(item) =>{ return item.linkedNode});
                     delete linkItem.linkedNode;
                     return linkItem;
                 })

                return this.addNodeLink(tenantTag, batchId,linkData, username);
            })
            .then(response => {
                console.log("Links are created successfully.")
            })
    }
    processInternalLinks(tenantTag,  internalLinks, toc, assignments) {
        return Promise.map(internalLinks, async (link) => {
           // console.log(link);
            try {
            let linkNode=  await  this.findLinkNode(tenantTag, link.linkednode , toc, link, assignments, link.linklabel);
            let node =  await  this.findLinkNode(tenantTag, link.node , toc, link, assignments, link.label);

            return {
                nodeId: node.assignmentId ||node.nodeId ,
                label: link.label,

                linkedNode: {
                    nodeId: linkNode.assignmentId ||linkNode.nodeId ,
                    label: link.linklabel,
                    relationtype: link.relationtype,
                    purpose: link.purpose
                }
            }
            } catch(err){
                console.log("link object", link)
                console.log("Link node",err);
                return Promise.reject(err);
            }

            
        }, {concurrency:1})
        .then( links =>{
            console.log("links ", links)
            return links;
        })

    }
    processExternalLinks( tenantTag,  externalLinks, toc, assignments) {


        let externalCourseIds = _.uniq(_.map(externalLinks, "courseId"));
        if (externalCourseIds.length == 0) {
            console.log("external course not found in the sheet.")
            return Promise.resolve([]);
        }
        return this.findCourses(externalCourseIds)
            .then(externalCourses => {
                return this.findTocs(externalCourses);
            })
            .then(async (data) => {
               

                if (externalCourseIds.length != data.tocs.length) {
                    console.log("Course id: ", externalCourseIds);
                    console.log("course response", data.tocs);
                    console.log("Link Course, not found structure details.");
                    alert("Linked Course not found structure details")
                    throw "Link Course, not found structure details."
                }

                return Promise.map(externalLinks,  (link) => {

                    let linkCourse = _.find(data.courses, { id: link.courseId });
                    let linkedToc = _.find(data.tocs, { uuid: linkCourse.structureId });
                    
                   return Promise.props( {
                       linkNode: this.findLinkNode(tenantTag, link.linkednode , linkedToc, link, assignments,link.linklabel),
                       node: this.findLinkNode(tenantTag, link.node , toc, link, assignments, link.label)
                    })
                       .then( data => {

                        let {node, linkNode} = data;

                        return {
                            nodeId: (node &&  node.assignmentId) || node.nodeId,
                            label: link.label,
                            linkedNode: {

                                nodeId: linkNode.assignmentId || linkNode.nodeId,
                                label: link.linklabel,
                                relationtype: link.relationtype,
                                purpose: link.purpose
                            }
                        }

                       })
                })
            })
            .then(externalLinks  =>{
                console.log("External links ", externalLinks)
                return   externalLinks;
            })
    }
     async findLinkNode(tangentTag,tag, toc,link,assignments, label) {
        
        let {  relationship } = link;
        
        let nodeTags = tag.split(".");
        let concept = this.findNodeByTag(nodeTags[0], toc.nodes);
        let topic = this.findNodeByTag(nodeTags[1], concept.nodes);
        let subtopic = this.findNodeByTag(nodeTags[2], topic.nodes);
        if(!subtopic){
            console.log("link tag ", nodeTags)
            console.log("Concept", JSON.stringify(concept));
            console.log("topic", JSON.stringify(topic));
            alert("not find in subtopic", nodeTags)
            throw "not find in subtopic";
        }

        let assignmentName = nodeTags[3]  || "";
        let assignment =null;
        if(relationship == "internal"  && assignmentName!= "") {
            assignment = _.find( assignments, {name: assignmentName});
            
            
        } else if( assignmentName!= "") {
            assignment =  await this.findAssignments(tangentTag, topic.nodeId, subtopic.nodeId);
            assignment = _.find( assignments, {name: assignmentName});
            
        }
        let nodes = {
            concept,
            topic,
            subtopic,
            assignment
        };
        return nodes[label];
        
    }

    findNodeByTag(tag, nodes) {
        if (!nodes) return null;
        return _.filter(nodes,(node )=> {  
             if(node.metadata.tag == tag) return node;
            }
        )[0];
    }

    findCourses(courseIds) {
        return Promise.map(courseIds, courserId => {
            return new Promise((resolve, reject) => {
                QikikAdminProvider.getCourseById(courserId, (response, statusCode, error) => {
                    if (statusCode != 200)
                        return reject(error);
                    else
                        return resolve(response);
                })
            })
        })
    }

    findTocs(courses) {
        return Promise.map(courses, course => {
            if(!course) {
                console.log("Course not found");
                return Promise.reject("External Link course not found.")
            }
            return new Promise((resolve, reject) => {
                QikikAdminProvider.getFullTocTree(course.structureId, (response, statusCode, error) => {
                    if (statusCode != 200)
                        return reject(error);
                    else
                        return resolve(response);
                })
            })
            
        })
        .then(tocsResponse =>{
            return {tocs:tocsResponse, courses};
        })
    }
    findAssignments(tangentTag, nodeId1, nodeId2) {
        return Promise.all([AAPI.fetchAssignments(tangentTag, nodeId1), AAPI.fetchAssignments(tangentTag, nodeId2)])
            .then(assignments => {
                return _.flatMapDeep(assignments);
            })
    }
    findAssignmentNamesFromCSV(assets) {

        let assignments = _.map(assets, "PracticeAssignment");
        assignments.push([..._.map(assets, "LearnAssignment")])
        assignments.push([..._.map(assets, "CheckAssignment")])
        return _.filter(_.uniq(assignments), a => (a != ""));
    }
    

}

export default new LinkProvider()